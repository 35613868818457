
import PanelMenu from 'primevue/panelmenu'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import Breadcrumb from 'primevue/breadcrumb'
import { defineComponent, reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    PanelMenu,
    Button,
    Sidebar,
    Breadcrumb
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const leftMenu = reactive({
      initialized: false,
      visible: false,
      items: [
        {
          class: 'close-menu p-d-md-none',
          icon: 'pi pi-fw pi-chevron-left',
          command: () => {
            leftMenu.visible = false
          }
        },
        {
          label: 'Home',
          icon: 'pi pi-fw pi-home',
          //to: '/'
          command: () => {
            router.push('/')
            leftMenu.visible = false
          }
        },
        {
          label: 'About Me',
          icon: 'pi pi-fw pi-id-card',
          to: '/about',
          command: () => {
            router.push('/about')
            leftMenu.visible = false
          }
        },
        {
          label: 'Social Media',
          icon: 'pi pi-fw pi-thumbs-up',
          to: '/social',
          command: () => {
            router.push('/social')
            leftMenu.visible = false
          }
        },
        {
          label: 'Awesome Projects',
          icon: 'pi pi-fw pi-star-o',
          to: '/awesome',
          command: () => {
            router.push('/awesome')
            leftMenu.visible = false
          }
        }
      ]
    })
    const topMenu = reactive({
      items: [
        {
          label: 'Menu',
          icon: 'pi pi-fw pi-bars',
          command: () => {
            leftMenu.visible = !leftMenu.visible
          }
        }
      ]
    })
    const breadcrumbs = reactive({
      home: { label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
      items: computed(() => {
        const newItems: any[] = []

        let menuItems: any = leftMenu.items
        while (menuItems) {
          const items = menuItems
          menuItems = null
          for (const item of items) {
            if (!item.to || item.to == '/') {
              continue
            }

            if (!route.path.startsWith(item.to)) {
              continue
            }

            newItems.push(item)
            menuItems = item.items
            break
          }
        }

        return newItems
      })
    })

    onMounted(() => {
      leftMenu.initialized = true
    })

    return {
      topMenu,
      leftMenu,
      breadcrumbs
    }
  }
})
