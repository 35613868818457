
import Card from 'primevue/card'
import { defineComponent } from 'vue'
import Colors from '@/components/Colors.vue'

export default defineComponent({
  components: {
    Card,
    Colors
  }
})
