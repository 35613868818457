export default [
  {
    path: '/',
    name: 'Home',
    view: 'index'
  },
  {
    path: '/about',
    name: 'About'
  },
  {
    path: '/social',
    name: 'Social Media'
  },
  {
    path: '/awesome',
    name: 'Awesome Projects'
  }
]
