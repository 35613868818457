
import Button from 'primevue/button'
import Card from 'primevue/card'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Button,
    Card
  }
})
